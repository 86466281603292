.itemContainer {
  display: flex;
  width: 680px;
  height: 180px;
  margin: 0 0 12px 0;
  @media (max-width: 935px) {
    width: 350px;
    height: 165px;
    margin-bottom: 40px;
  }

  .image {
    max-width: 180px;
    object-fit: fill;
    @media (max-width: 935px) {
      width: 85px;
      height: 85px;
    }
  }

  .itemInfoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    padding-bottom: 40px;


    .topContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 14px;

      font-family: "Inter";
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;

      @media (max-width: 935px) {
        font-size: 20px;
        text-align: left;
        margin-bottom: 10px;
      }

    }

    .optionsContainer {
      display: flex;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      gap: 10px;
      @media (max-width: 935px) {
        height: 16px;
        align-items: center;
      }

      .option::first-letter {
        text-transform: uppercase;
      }
    }

    .itemPrice {
      @media (max-width: 935px) {
        display: flex;
        flex-direction: row;
        margin-top: 18px;
        align-items: center;
        height: 30px;
      }
    }

    .priceDesktop {
      display: block;
      @media (max-width: 935px) {
        display: none;
      }
    }

    .priceMobile {
      display: none;
      @media (max-width: 935px) {
        display: block;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
      }
    }

    .buttonContainer {
      display: flex;
      margin-top: auto;

      @media (max-width: 935px) {
        width: 245px;
        margin-top: 21px;
      }

      .leftbutton {
        margin-right: 13px;
      }
    }
  }
}
