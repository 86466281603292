.checkbox-filter-group__category,
.checkbox-filter-group__coverage,
.checkbox-filter-group__color,
.checkbox-filter-group__coating,
.checkbox-filter-group__price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.checkbox-filter-group__color_gravel{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  margin-bottom: 40px;
}

.checkbox-filter-group__title {
  margin-bottom: 5px;
  font-weight: bold;
}

.checkbox-filter-group__checkbox {
  display: flex;
  align-items: center;
}

.checkbox-filter-group__checkbox input[type='checkbox'] {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  appearance: none;
  border: 2px solid #000;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  position: relative;
}

.checkbox-filter-group__checkbox input[type='checkbox']:checked {
  background-color: #000;
}

.checkbox-filter-group__checkbox input[type='checkbox']:checked::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 5px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: translate(-50%, -50%) rotate(-45deg);
  top: 50%;
  left: 50%;
}

.horizontal-slider {
  width: 100%;
  margin: 20px auto;
  position: relative;
}

.example-thumb {
  width: 30px;
  height: 30px;
  background-color: white;
  border: 1px solid #DB6358;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
  transform: translate(0, -50%);
  cursor: pointer;
  outline: unset;
  overflow: hidden;
}
.example-thumb-gravel{
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #DB6358;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
  transform: translate(0, -50%);
  cursor: pointer;
  outline: unset;
  left:258px;
  overflow: hidden;
}

.example-track {
  background-color: #DB6358;
  height: 2px;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.example-track2 {
  background-color: #db635800;
  height: 2px;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.vertical-slider {
  width: 1px;
  height: 236px;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin-left: 118px;
}

.vertical-slider-thumb {
  width: 4px;
  height: 82px;
  background-color: black;
  cursor: pointer;
}

.vertical-slider-track {
  position: absolute;
  width: 4px;
  height: 236px;
  background-color: #D4D4D4;
  border-radius: 10px;
}


