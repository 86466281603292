.page {
  position: relative;
  width: 100%;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  text-align: center;
}

.filterGroupDesktop {
  display: none;
  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    width: 280px;
    margin: 88px 0 0 0;
  }
}

.filterGroup > fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.guarantee {
  margin: 0 0 40px 0;
}

.checkboxTitle {
  height: 30px;
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  margin: 0 0 4px 4px;
}

.slider {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  width: 225px;
  margin: 0 55px 0 0;
  max-height: 236px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    background-color: #D4D4D4;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 50px;
    max-height: 82px;
  }

  &::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }
}


.checkboxShowMore {
  background-color: #FFFFFF;
  border: 0;
  display: flex;
  height: 18px;
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin: 4px 0 0 0;
  gap: 8px;
  text-decoration: underline;
  cursor: pointer;

  .svgCollapse svg {
    width: 8px;
    height: 4px;
    border: 2px;
  }
}

.checkboxShowMore .svgCollapse {
  display: flex;
  align-self: center;
}

.checkboxShowMore .svgCollapse.rotated {
  transform: rotate(180deg);
}

.priceInput {
  flex: 1;
  text-align: left;
  padding: 10px 13px;
  border: 1px solid #0E0E0E;
  border-radius: 4px;
  width: 0;
}

.searchRow {
  display: flex;
  flex-direction: column;
  margin: 31px 0 24px 0;
  gap: 24px;

  border-radius: 10px;
  @media (min-width: 900px) {
    display: flex;
    flex-direction: row;
    height: 30px;
    width: 880px;
    gap: 10px;
    justify-content: end;
    margin: 80px 0 50px 0;
  }
}

.filterButton {
  margin-left: auto;
  @media (min-width: 900px) {
    display: none;
  }
}

.mainTableContainer {
  flex-direction: column;
}

.titleContainer {
  display: none;
  @media (min-width: 900px) {
    display: grid;
    grid-template-columns: 111px 195px 96px 220px 162px;
    margin: 0 0 16px 0;
    text-align: center;
    width: 823px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }

  .title {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
  }

  .marginLeft20 {
    @media (min-width: 900px) {
      margin-left: 20px;
    }
  }
}

.buttonShowMore {
  margin: 20px 0 0 0;
  @media(min-width: 900px) {
    width: 170px;
  }
}

.rightSide {
  margin-left: unset;
  @media(min-width: 900px) {
    margin-left: 16px;
  }
}


