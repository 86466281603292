.iconAdjustmentButton {
  font-family: Inter;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
}

.plus{
  width: 16px;
  padding: 0 10px 0 2px;
}

.minus{
  width: 11px;
  padding: 0 2px 0 10px;
}

.content {
  font-family: Inter;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  width: 44px;
  border: 0;
  text-align: center;
  border-radius: 0;
  background-color: transparent;
}

.container {
  justify-content: space-between;
  min-width: 100px;
  padding: 0;
  cursor: default;
  width: 100px;
}

.content:focus {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
