.mainContainer {
  display: flex;
  width: 100%;
  gap: 4px;
  align-items: center;
}
.title{
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  margin: 0 0 10px 0;

}
.priceInput {
  flex: 1;
  text-align: left;
  padding: 10px 13px;
  border: 1px solid #0E0E0E;
  border-radius: 4px;
  width: 100px;
}

