
.white {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 29px;
  gap: 10px;
  border: 1px solid #000000;
  border-radius: 30px;
  background-color: #ffffff;
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.white.hoverable:hover {
  background-color: #000000;
  color: #ffffff;
}


.black {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 29px;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.black.hoverable:hover {
  background-color: #ffffff;
  color: #000000;
}

@media (max-width: 935px) {
  .white, .black {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    height: 45px;
    border-radius: 66px;
    padding: 8px 36px;
    gap: 10px;
  }
}

.white.maxWidth {
  width: 100%;
}

.black.maxWidth {
  width: 100%;
}

