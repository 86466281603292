.buttonAddToCart {
  width: 130px;
  height: 40px;
  padding: 0 6px 0 6px;
  gap: 6px;
  border-radius: 40px;
  border: 1px solid black;
  background-color: white;

  display: flex;
  align-items: center;

  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;

  cursor: pointer;
}

.buttonInCart {
  width: 130px;
  height: 40px;
  padding: 0 6px 0 6px;
  gap: 6px;
  border-radius: 40px;
  border: 1px solid black;
  background-color: black;
  color:white;

  display: flex;
  align-items: center;

  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;

  cursor: pointer;
}
