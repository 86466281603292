.page {
  position: relative;
  width: 100%;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  text-align: center;
}

.filterGroupDesktop {
  display: none;
  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    width: 280px;
    gap: 20px;
  }
}

.filterGroupMobile {
  box-sizing: border-box;
  position: fixed;
  inset: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  z-index: 909;
  padding: 90px 20px;
  @media (min-width: 900px) {
    display: none;
  }
  transition: opacity ease-in-out .3s;
  pointer-events: none;
  opacity: 0;
  gap: 16px;
  &.show {
      pointer-events: all;
      opacity: 1;
  }
  & button {
    width: 100%;
  }
}

.filterGroup > fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.items {
  display: grid;
  grid-column-gap: 20px;
  justify-content: center;
  @media (min-width: 640px) {
    grid-template-columns: 1fr 1fr;
    width: 600px;
    margin: auto;
  }
  @media (min-width: 1180px) {
    grid-template-columns: 1fr 1fr 1fr;
    width: 880px;
    margin-left: 20px;
  }
}

.searchRow {
  display: flex;
  flex-direction: column;
  margin: 24px 20px 32px;
  gap: 16px;
  @media (min-width: 900px) {
    flex-direction: row;
    margin: 24px 0 32px;
  }
}

.filterButton {
  margin-left: auto;
  cursor: pointer;
  @media (min-width: 900px) {
    display: none;
  }
}

.priceInput {
  flex: 1;
  text-align: left;
  padding: 10px 13px;
  border: 1px solid #0E0E0E;
  border-radius: 4px;
  width: 0;
}

.tipRow {
  display: flex;
  align-items: center;
  flex: 1;

  & svg {
    flex: none;
  }

  @media(max-width: 935px) {
    align-items: start;
  }
}

.tip {
  max-width: 520px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  @media(max-width: 935px) {
    text-align: start;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


body {
  font-family: 'Inter', sans-serif;
}
