.page {
  position: relative;
  width: 350px;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  margin: auto;
  @media (min-width: 935px) {
    width: 680px;
  }
}

.titleOrderContainer {
  display: flex;
  justify-content: space-between;
  margin: 100px 0 60px 0;
  @media(min-width: 935px) {

  }

  .titleOrder {
    margin: 0;
    font-family: 'Inter';
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    @media (min-width: 935px) {
      display: flex;
      font-family: 'Inter';
      font-size: 50px;
      font-weight: 400;
      line-height: 60px;
      text-align: left;
      height: 60px;
    }
  }

  .svgClose {
    width: 18px;
    height: 18px;
    display: flex;
    align-self: center;
    cursor: pointer;
    @media(min-width: 935px) {
      position: absolute;
      right: -100px;
      top:30px;
      width: 27px;
      height: 27px;
    }
  }
}


.itemTotalPriceContainer {
  display: flex;
  font-family: 'Inter';
  justify-content: space-between;
  align-items: center;
  margin: 48px 0 60px 0;
  @media (max-width: 935px) {
    margin: 20px 0 60px 0;
    width: 350px;
    height: 50px;

  }

  .titlePrice {
    display: flex;
    align-items: center;
    font-size: 30px;
    text-align: right;
    //width: 245px;
    height: 50px;
    @media (max-width: 935px) {
      display: flex;
      align-items: center;
      height: 20px;
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .totalPrice {
    font-size: 30px;
    font-weight: 400;
    line-height: 50px;
    text-align: right;

    @media (max-width: 935px) {
    }
  }
}


