.checkboxShowMore {
  background-color: #FFFFFF;
  border: 0;
  display: flex;
  height: 18px;
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin: 4px 0 0 4px;
  padding: 0;
  gap: 8px;
  text-decoration: underline;
  cursor: pointer;

  .svgCollapse svg {
    width: 8px;
    height: 4px;
    border: 2px;
  }
}

.checkboxShowMore .svgCollapse {
  display: flex;
  align-self: center;
}

.checkboxShowMore .svgCollapse.rotated {
  transform: rotate(180deg);
}