.container {
  display: flex;
  height: 40px;
  border: solid 1px black;
  border-radius: 40px;
  align-items: center;
  padding: 0 21px;
  cursor: pointer;
  user-select: none;
  @media (max-width: 935px) {
    padding: 0 19px;
  }

  .leftIcon {
    margin-right: 9px;
    width: 18px;
    height: 20px;
  }

  .title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    width: 64px;
  }
}

.cursorDefault {
  cursor: default;
}
