.product-category {
  width: 280px;
  height: 518px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 48px;
}

.category-title {
  width: 280px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #0E0E0E;
  margin-top: 16px;
  margin-bottom: 16px;
  text-decoration: none;
}

.product-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.products-container {
  flex: 1;
}

.product-info {
  width: 280px;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0e0e0e;
}

.price-info {
  width: 280px;
  height: 40px;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  color: #0e0e0e;
  margin-bottom: 16px;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details-button,
.add-to-cart-button {
  width: 134px;
  height: 50px;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #0E0E0E;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.add-to-cart-button {
  background: #ffffff;
  color: #0E0E0E;
}

.add-to-cart-button:hover {
  background: #0E0E0E;
  color: #FFFFFF;
}

.details-button {
  background: #0e0e0e;
  color: #ffffff!important;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.details-button:hover {
  background: #FFFFFF;
  color: #000000!important;
}

.product-image {
  width: 100%;
  height: 280px;
  background-color: #000;
}
