.search-container {
  position: relative;
  height: 40px;
  border: 1px solid #0E0E0E;
  border-radius: 10px;
  width: 100%;
  @media (min-width: 900px) {
    width: 180px;
    height: 30px;
    border-radius: 4px;
  }
}

.search-input {
  width: 95%;
  height: 35px;
  margin-top: 2px;
  border: none;
  outline: none;
  padding: 0px 12px; /* Отступы внутри поля ввода */
  font-family: 'Inter'; /* Шрифт */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #0E0E0E; /* Цвет текста */
  @media (min-width: 900px) {
    margin-top: unset;
    width: 155px;
    height: 30px;
  }
  @media(max-width: 935px) {
    width: 90%;
  }
}

.search-icon {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 8px; /* Отступ от правого края */
  top: 50%; /* Позиционирование по вертикали */
  transform: translateY(-50%);
  border: 2px solid #0e0e0e00;
}

.search-input::placeholder {
  color: black; /* Устанавливаем цвет placeholder'а */
}
