.mainContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 310px;
  padding: 20px;
  margin: 0 0 10px 0;
  border-radius: 24px;
  border: 1px solid black;
  @media(min-width: 900px) {
    position: relative;
    text-align: left;
    border: 0;
    width: 823px;
    height: 40px;
    margin: 0 0 10px 0;
    padding: 0;
  }
}

.optionContainer {
  height: 152px;
  width: 180px;
  text-align: left;
  @media(min-width: 900px) {
    position: relative;
    display: grid;
    grid-template-columns: 111px 195px 96px 220px 162px;
    border: 1px solid black;
    border-radius: 30px;
    height: 40px;
    align-content: center;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    width: 823px;
  }

  .option::first-letter {
    text-transform: uppercase;
  }

  .option {
    margin-top: 4px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    height: 20px;

    @media(min-width: 900px) {
      margin-top: 0;
      text-align: left;
      line-height: 30px;
      height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .optionFirst {
    margin: 0 0 6px 0;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;

    @media (min-width: 900px) {
      margin: 0 0 0 20px;
      font-size: 16px;
      line-height: 30px;
    }
  }

  .pricesDesktop {
    display: none;
    @media (min-width: 900px) {
      display: flex;
    }
  }

  .pricesModile {
    display: flex;
    margin-top: 6px;
    height: 40px;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    align-items: center;
    @media (min-width: 900px) {
      display: none;
    }
  }
}

.titleContainer {
  width: 130px;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #A0A0A0;
  @media(min-width: 900px) {
    display: none;
  }

  .title {
    width: 60px;
    height: 20px;
    line-height: 20px;
    margin: 4px 0 0 17px;
  }

  .titleFirst {
    margin: 0 0 6px 17px;
  }

}

.iconCartDesktop {
  display: none;
  @media(min-width: 900px) {
    position: absolute;
    right: -57px;
    display: flex;
    margin: 0 0 10px 0;
    cursor: pointer;
  }
}

.iconCartDesktop:hover svg path,
.iconCartDesktop:hover svg circle {
  fill: black;
  stroke: white;
}

.iconCartMobile {
  display: flex;
  justify-content: end;
  cursor: pointer;
  margin-top: 10px;
  @media (min-width: 900px) {
    display: none;

  }
}

.gost {
  font-size: 14px!important;
}