.page {
  position: relative;
  width: 100vw;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
  @media (min-width: 900px) {
    flex-direction: row;
  }
}

.mobileHeader {
  font-weight: 600;
  font-size: 36px;
  line-height: 30px;
  color: #0E0E0E;
  margin: 0;
  padding: 12px 20px 32px 20px;
}

.desktopHeader {
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  color: #0E0E0E;
  display: none;
  @media (min-width: 900px) {
    display: block;
  }
}

.image {
  width: 100%;
  object-fit: contain;
  @media (min-width: 900px) {
    margin: 0;
    width: 680px;
    height: 580px;
  }
}

.details {

}

.data {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 600px;
  gap: 60px;
  margin-left: 20px;
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media(max-width: 935px) {
    gap: 36px;
    padding-bottom: 32px;
  }
}

.dataPoint {
  display: flex;

  & svg {
    margin-right: 48px;
    flex-shrink: 0;
  }

  @media(max-width: 935px) {
    padding: 0 4px 0 4px;
    gap: 24px;
    & svg {
      margin-right: 0;
      height: 48px;
      width: 48px;
      //align-self: center;
      padding-top: 8px;
    }
  }
}

.desktopSvg {
  display: block;
  @media (max-width: 935px) {
    display: none;
  }
}

.mobileSvg {
  @media (min-width: 935px) {
    display: none;

  }
}

.dataPointTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  @media (max-width: 935px) {
    line-height: 26px;
    text-align: left;

  }
}

.dataPointValue {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  margin-top: 10px;
  @media (max-width: 935px) {
    margin-top: 8px;
    font-weight: 600;
    font-size: 32px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 20px; //figma - paragraph: 20px;
  }
}

.sizeItem {
  @media (max-width: 935px) {
    margin-top: 8px;
    font-weight: 600;
    font-size: 32px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 20px;
  }
}

.picker {
  width: 100%;
  max-width: 980px;
  text-align: left;
  margin: 20px;
  @media (min-width: 900px) {
    margin-left: 60px;
  }
  @media (min-width: 1440px) {
    margin-left: 120px;
  }
}

.size {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 40px;
  margin-bottom: 40px;
  @media (max-width: 935px) {
    display: none;
  }
}

.colorsContainer {
  padding-right: 36px;
  @media (min-width: 900px) {
    padding-right: unset;
  }
  @media (max-width: 935px) {
    padding-top: 16px;

  }
}

.colors {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  padding: 8px 0;
  margin-bottom: 22px;
  justify-content: center;
  @media (min-width: 900px) {
    justify-content: unset;
  }
  @media(max-width: 935px) {
    //width: 358px;
    padding: 12px 16px 28px 16px;
    justify-content: space-evenly;
    gap: 40px;
  }
}

.colorGroup {
  display: flex;
  cursor: pointer;
  padding: 22px 0;
  color: #0E0E0E;
  &.disabled {
    color: #BEBEBE;
    border-bottom: 1px solid #0e0e0e;
    cursor: unset;
  }
  @media (max-width: 935px) {
    margin: 0;
    height: 64px;
    align-items: center;
    padding: 16px 4px 16px 4px;
    justify-content: space-between;
  }
}

.colorGroupHeader {
  &::first-letter {
    text-transform: capitalize;
  }
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  flex: 1;
  @media(max-width: 935px) {
    font-size: 26px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;

  }
}

.arrowSvg {
  width: 26px;
  height: 14px;
  @media (max-width: 935px) {
    width: 32px;
    height: 32px;
  }
}


.colorItem {
  text-align: center;
  &::first-letter {
    text-transform: capitalize;
  }
  cursor: pointer;
  @media (max-width: 935px) {
    height: 179px;
    //gap: 6px;
  }
}

.colorName {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 8px;
  height: 30px;
  @media (max-width: 935px) {
    margin-bottom: 6px;
    text-align: center;
  }
}

.colorPrice {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-top: 4px;
  @media (max-width: 935px) {
    margin-top: 6px;
    gap: 8px;
    font-weight: 500;
    //text-align: left;

  }
}

.smallImgDesktop {
  width: 107px;
  height: 107px;
  border-radius: 50%;
  object-fit: none;
  @media(max-width: 935px) {
    display: none;
  }
}
.smallImgMobile {
  width: 107px;
  height: 107px;
  display: none;
  @media(max-width: 935px) {
    display: block;
    border-radius: 24px;
    object-view-box: inset(40% 20% 20% 40%);
  }
}

.smallImgSelected {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  object-fit: none;
  border: 4px solid #0E0E0E;
  padding: 2px;
}

.colorContainer {
  border-bottom: 1px solid #0e0e0e;
}

.sizeAdaptiveMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 20px 52px 20px;
  gap: 20px;
  //width: 349px;
  @media (min-width: 935px) {
    display: none;
  }
}

.tileHeight {
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.buttonsContainerMobile {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
}

.actionRow {
  display: none;
  @media (min-width: 935px) {
    display: flex;
    gap: 24px;
    margin-top: 16px;
  }
}

.margins {
  @media (min-width: 935px) {
    margin-top: 72px;
    margin-bottom: 96px;
  }
}


